<template>
  <v-simple-table
    v-if="messageList.length"
    fixed-header
    class="inbox"
  >
    <template v-slot:default>
      <thead>
        <tr>
          <th>Date</th>
          <th>Subject</th>
          <th>Participants</th>
        </tr>
      </thead>
      <tbody>
        <router-link
          v-for="userThread in messageList"
          :key="userThread.id"
          tag="tr"
          :to="{ name: 'MessageThread', params: { id: userThread.id }}"
          class="router-link"
        >
          <td>{{ userThread.thread.latest_message.sent_at|formatDate }}</td>
          <td :class="{'unread': (userThread.unread == true)}">
            {{ userThread.thread.subject }}
          </td>
          <td>
            <UserAvatarList :user-list="userThread.thread.users" />
          </td>
        </router-link>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import UserAvatarList from '@/components/UserAvatarList.vue'

export default {
  components: {
    UserAvatarList,
  },
  props: {
    messageList: {
      type: Array,
      required: true,
    }
  },
}
</script>
