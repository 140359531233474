<template>
  <div class="avatar-list">
    <router-link
      v-for="user in userList"
      :key="user.username"
      :to="{ name: 'SocialProfile', params: { username: user.username }}"
    >
      <v-avatar
        size="24"
        class="mr-1"
      >
        <img
          :src="user.profile.avatar_display_url"
          :alt="user.username + ' avatar'"
        >
      </v-avatar>
    </router-link>
  </div>
</template>

<script>

export default {
  props: {
    userList: {
      type: Array,
      required: true,
    }
  }
}
</script>
