<template>
  <div>
    <v-btn
      :disabled="!canRefreshMessages"
      class="mr-4"
      @click="refreshMessages"
    >
      Refresh
    </v-btn>
    <v-btn
      :to="{name: 'MessageSend'}"
    >
      New
    </v-btn>
    <ThreadList
      v-if="messageList"
      class="mt-4"
      :message-list="messageList"
    />
    <div v-else>
      No messages found.
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ThreadList from '@/components/ThreadList.vue'

export default {
  components: {
    ThreadList,
  },
  data: function() {
    return {
      success: null,
      errorMessages: null,
    }
  },
  computed: {
    ...mapGetters({
      messageList: 'messages/orderByLatestMessage',
    }),
    canRefreshMessages() {
      return this.$online
    },
  },
  created: function () {
    this.$store.dispatch('messages/getAll')
  },
  methods: {
    refreshMessages() {
      this.$store.dispatch('messages/getAll')
    },
  },
}
</script>
